<template>
  <v-row dense>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="client.company_name"
        label="Razão Social*"
        placeholder="Digite a razão social"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="client.cnpj"
        outlined
        label="CNPJ"
        v-mask="'##.###.###/####-##'"
        placeholder="xx.xxx.xxx/xxxx-xx"
        rounded
        dense
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="client.trading_name"
        label="Nome fantasia*"
        placeholder="Digite o nome fantasia"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="client.state_registration"
        outlined
        label="Inscrição Estadual"
        placeholder="Digite a inscrição estadual"
        rounded
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
  },
  deactivated() {
    this.$emit('deactivated')
  },
}
</script>
